.print-only {
  display: none;
}

@media print {
  .print-only {
    display: block;
  }

  .no-print {
    display: none !important;
  }

  @page {
    margin: .5in;
  }

  body {
    color: #000;
    line-height: 1.3;
    background: #fff !important;
  }

  h1, h2, h3, h4 {
    margin-bottom: .25in;
  }

  h1 {
    font-size: 24pt;
  }

  h3, h4 {
    margin-top: 25px;
    font-size: 16pt;
  }

  h3 {
    margin-bottom: .25in;
    font-size: 18pt;
  }

  h4 {
    margin-bottom: .15in;
    font-size: 16pt;
  }

  a {
    page-break-inside: avoid;
  }

  .activity:not(:last-of-type) {
    border-bottom: 1px solid var(--mid-gray);
    margin-bottom: .25in;
    padding-bottom: .25in;
  }

  blockquote {
    page-break-inside: avoid;
  }

  h1, h2, h3, h4, h5, h6 {
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  img {
    page-break-inside: avoid;
    page-break-after: avoid;
  }

  table, pre {
    page-break-inside: avoid;
  }

  ul, ol, dl {
    page-break-before: avoid;
  }

  a:link, a:visited, a {
    color: #520;
    text-align: left;
    background: none;
    font-weight: bold;
    text-decoration: underline;
  }

  a {
    page-break-inside: avoid;
  }

  a[href^="http"]:after {
    content: " <" attr(href) "> ";
  }

  article a[href^="#"]:after {
    content: "";
  }

  a:not(:local-link):after {
    content: " <" attr(href) "> ";
  }

  p, address, li, dt, dd, blockquote {
    font-size: 100%;
  }

  code, pre {
    font-family: Courier New, Courier, mono;
  }

  ul, ol {
    margin-bottom: 20pt;
    margin-left: 18pt;
    list-style: square;
  }

  li {
    line-height: 1.6em;
  }
}

/*# sourceMappingURL=index.eec2408b.css.map */
