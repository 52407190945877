.print-only {
	display: none;
}

@media print {
	.print-only {
		display: block;
	}
	.no-print {
		display: none !important;
	}

	@page {
		margin: 0.5in;
	}

	body {
		line-height: 1.3;
		background: #fff !important;
		color: #000;
	}

	h1,
	h2,
	h3,
	h4 {
		margin-bottom: 0.25in;
	}

	h1 {
		font-size: 24pt;
	}

	h3,
	h4 {
		font-size: 16pt;
		margin-top: 25px;
	}

	h3 {
		font-size: 18pt;
		margin-bottom: 0.25in;
	}

	h4 {
		font-size: 16pt;
		margin-bottom: 0.15in;
	}

	a {
		page-break-inside: avoid;
	}

	.activity:not(:last-of-type) {
		border-bottom: 1px solid var(--mid-gray);
		padding-bottom: 0.25in;
		margin-bottom: 0.25in;
	}

	blockquote {
		page-break-inside: avoid;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		page-break-after: avoid;
		page-break-inside: avoid;
	}

	img {
		page-break-inside: avoid;
		page-break-after: avoid;
	}

	table,
	pre {
		page-break-inside: avoid;
	}

	ul,
	ol,
	dl {
		page-break-before: avoid;
	}

	a:link,
	a:visited,
	a {
		background: transparent;
		color: #520;
		font-weight: bold;
		text-decoration: underline;
		text-align: left;
	}

	a {
		page-break-inside: avoid;
	}

	a[href^="http"]:after {
		content: " <" attr(href) "> ";
	}

	article a[href^="#"]:after {
		content: "";
	}

	a:not(:local-link):after {
		content: " <" attr(href) "> ";
	}

	p,
	address,
	li,
	dt,
	dd,
	blockquote {
		font-size: 100%;
	}

	code,
	pre {
		font-family: "Courier New", Courier, mono;
	}

	ul,
	ol {
		list-style: square;
		margin-left: 18pt;
		margin-bottom: 20pt;
	}

	li {
		line-height: 1.6em;
	}
}
